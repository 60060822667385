@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

@fonts: "Noto Sans JP", sans-serif;

body {
  background: #f7f8fb; // Base
  font-family: @fonts;
  font-size: 0.875rem;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: @fonts;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
